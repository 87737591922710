var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0"},[_c('v-card',{staticClass:"pb-2 mb-6"},[_c('div',{staticClass:"full-width header"},[_c('p',{staticClass:"pt-4 pl-4"},[_vm._v(_vm._s(_vm.todayDate))]),_c('div',{staticClass:"mt-3 d-flex justify-center"},[_c('div',{staticClass:"dropdown"},[_c('v-select',{staticClass:"year-select",attrs:{"dense":"","solo":"","background-color":"#eaedf0","label":"Year","items":_vm.options,"item-text":"label","item-value":"value"},on:{"change":_vm.handelYearChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex position-absolute justify-center year-text"},[_vm._v(" "+_vm._s(item.label)+" ")])]}}]),model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)])]),_c('DataTable',{staticClass:"mx-2 monthly-report",attrs:{"headers":_vm.headers,"items":_vm.reportItems,"total-records":12,"number-of-pages":1,"items-per-page":12,"disablePagination":true,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"px-1",style:({ 'border-bottom': _vm.lastItemCheck(index) })},[_c('p',{staticClass:"headings"},[_vm._v(_vm._s(item.month + "月"))])]),_c('td',{staticClass:"text-center",style:({ 'border-bottom': _vm.lastItemCheck(index) })},[(item.sales)?_c('p',{staticClass:"headings"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.sales))+" ")]):_c('p',{staticClass:"headings"},[_vm._v("￥--")])]),_c('td',{staticClass:"text-center px-1",style:({ 'border-bottom': _vm.lastItemCheck(index) })},[(item.number_of_total_customer)?_c('p',{staticClass:"headings"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.number_of_total_customer))+" ")]):_c('p',{staticClass:"headings"},[_vm._v("--")])]),_c('td',{staticClass:" text-center px-1",style:({ 'border-bottom': _vm.lastItemCheck(index) })},[(item.average_customer_spend)?_c('p',{staticClass:"headings"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.average_customer_spend))+" ")]):_c('p',{staticClass:"headings"},[_vm._v("￥--")])]),_c('td',{style:({ 'border-bottom': _vm.lastItemCheck(index) })},[_c('div',{staticClass:"headings text-center d-flex align-center"},[(item.average_in_shop_customer_spend)?_c('p',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.average_in_shop_customer_spend))+" ")]):_c('p',{staticClass:"pr-2"},[_vm._v("￥--")]),_c('v-btn',{staticClass:"ml-auto pa-0",attrs:{"small":"","rounded":"","min-width":"20","width":"20","height":"20","text":"","to":{
                  name: 'monthlyReport',
                  params: { date: (_vm.year + "-" + (item.month)) }
                }}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("$goNext")])],1)],1)])])]}}])}),(_vm.summary)?_c('v-row',{staticClass:"summary font-weight-bold mx-2 mt-3 mb-3 pr-6",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center"},[_vm._v("累計")]),_c('v-col',{staticClass:"d-flex align-center justify-center"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.summary.sales)))]),_c('v-col',{staticClass:"d-flex align-center justify-center"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.summary.number_of_total_customer)))]),_c('v-col',{staticClass:"d-flex align-center justify-center"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.summary.average_customer_spend)))]),_c('v-col',{staticClass:"d-flex align-center justify-center"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.summary.average_in_shop_customer_spend)))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }